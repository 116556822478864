<template>
    <div v-if="user.id">
        <user-interface />
    </div>
    <div v-else>
        <login-form />
    </div>
    <div>
        <footer-logo />
    </div>

    <div class="container-fluid" v-if="debug">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb small">
                <li class="breadcrumb-item">
                    <strong>Debug</strong>
                </li>
                <li class="breadcrumb-item">
                    .env file:
                    <code>{{ env_file }}</code>
                </li>
                <li class="breadcrumb-item">
                    Proxy endpoint:
                    <code>{{ proxy_endpoint }}</code>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script>
import UI from './ui.vue'
import LoginForm from './login.vue'
import FooterLogo from './footer.vue'

export default {
    name: 'main-page',
    components: {
        'user-interface': UI,
        'login-form': LoginForm,
        'footer-logo': FooterLogo,
    },
    data() {
        return {
            debug: sessionStorage.getItem('console.debug'),
            componentKey: 0,
            user: {
                id: sessionStorage.getItem('user.id')
            },
            timestamp: Date(),
            env_file: process.env.VUE_APP_ENV_FILE,
            proxy_endpoint: process.env.VUE_APP_PROXY_URL,
        }
    },
}
</script>

<style>
body {
    margin-top: 70px;
    /* If using navbar fixed-top */
    margin-bottom: 160px;
    /* If using navbar fixed-bottom */
}
</style>