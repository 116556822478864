<template>
  <navbartop />

  <mainpage />
</template>

<script>
import mainpage from './components/main.vue'
import navbartop from './components/navbartop.vue'

// Bootstrap 4 | https://getbootstrap.com/docs/4.6/getting-started/introduction/
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

export default {
  name: 'App',
  components: {
    mainpage,
    navbartop,
  },
}
</script>

<style>
@import "./goshorty.css";
#app {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin: 80px 10px 10px 10px;
}
</style>