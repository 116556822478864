<template>
  <a id="top"></a>

    <nav class="navbar navbar-dark bg-dark fixed-top">
        <a class="navbar-brand" href="/">
          <img src="@/assets/GoShortyIcon.png" alt="GoShorty logo" width="32" />
        </a>

        <span class="navbar-text">
          Insurance Intelligence
        </span>

        <form class="d-flex" v-if="user.id">
          <a v-on:click="logout()" class="btn btn-success">
            <strong>
              Logout
              <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
            </strong>
          </a>
        </form>
    </nav>
</template>

<script>
import axios from "axios";

export default {
  name: 'top-navbar',
  data() {
    return {
      user: {
        id: sessionStorage.getItem('user.id')
      }
    }
  },
  methods: {
    logout() {
      axios.defaults.withCredentials = true

      let logouturl = process.env.VUE_APP_PROXY_URL + 'logout.asp'

      let logoutconfig = {}

      let logoutdata = {
      }

      axios.post(logouturl, logoutdata, logoutconfig)
        .then(() => {
          sessionStorage.clear()
          document.location = '/'
        }, (error) => {
          if (error.response) {
            console.log('Logged out locally, but session may persist!')
            sessionStorage.clear()
            document.location = '/'
          }
        });
    }
  }
}
</script>