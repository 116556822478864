import { createApp } from 'vue'
import App from './App.vue'

// Third party
import { dom, library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Vue3Sanitize from "vue-3-sanitize"

library.add(far)
library.add(fas)
library.add(fab)

dom.watch()

const app = createApp(App)

app.component("font-awesome-icon", FontAwesomeIcon)

const allowed_html_tags = {
    allowedTags: ["i", "span"],
    allowedAttributes: {
        i: [ "class", "alt", "title" ],
        span: [ "class" ],
    }
};

app.mount("#app")

app.use(Vue3Sanitize, allowed_html_tags)