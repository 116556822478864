<template>
    <div class="container">
        <p>&nbsp;</p>
        <p>
            <img alt="Insurance Intelligence logo" src="@/assets/largeLogo.png" width="140" class="mx-auto d-block" />
        </p>
    </div>
</template>

<script>
export default {
    name: 'login-form',
}
</script>